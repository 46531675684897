<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('preparation_note_entry')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('preparation_note_entry')" :is-filter="false"/>
            </template>

            <div class="row mb-4">
                <div class="col-12 col-lg-3 col-xxl-2">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-12">
                            <b-form-group :label="$t('module')">
                                <active-module-selectbox
                                    type="english"
                                    v-on:activeModule="activeModule"
                                    v-model="moduleSelected"
                                ></active-module-selectbox>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-12">
                            <div
                                class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100 d-none d-lg-block"
                                style="height: calc(100vh - 220px); min-height: 300px; overflow-y: scroll"
                            >
                                <div v-if="isModule === true">
                                    <div v-if="isClass === true">
                                        <div v-for="(item, key) in setClass" v-bind:key="key">
                                            <b-button
                                                :variant="(item[0].class_id==class_id?'outline-primary':'outline-secondary')+' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 h-auto'"
                                                @click="getExams(item[0], key, item[0].class_id)"
                                            >
                                                {{ key }}
                                            </b-button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="spinner-border text-light mx-auto d-flex"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 col-xxl-10 mt-1 mt-lg-0">
                    <div v-if="isNoteAssessment === true">
                        <NoteAssessment
                            :items="itemNoteAssessment"
                            :class_id="class_id"
                            :schedule_name="schedule_name"
                            @getExam="getStudents(class_id, $event)"
                            @getExamName="getExamName = $event"
                        />
                    </div>
                    <div v-else>
                        <div v-if="isStudents === true">
                            <div class="d-block d-lg-flex align-items-center mb-4">
                                <h6 class="mb-2 mb-lg-0 text-muted">
                                    {{ $t('grades') }} - {{ getExamName }}
                                </h6>
                                <span class="mx-3 text-black-35 small d-none d-lg-block"
                                >&#124;</span
                                >
                                <h6 class="mb-0">{{ schedule_name }}</h6>
                            </div>

                            <b-table
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="setStudents"
                                :fields="fieldStudent"
                                show-empty
                                class="mb-0 table-dropdown border rounded"
                                style="height: calc(100vh - 220px); min-height: 300px; overflow-y: scroll"
                            >
                                <template #cell(score)="data">
                                    <b-form-input
                                        class="form-control-sm"
                                        :value="data.item.score"
                                        @keyup="handleInput(data.item.student_program_id, $event)"
                                        @keyup.down="handleInput(data.item.student_program_id, $event)"
                                        @keyup.up="handleInput(data.item.student_program_id, $event)"
                                        type="number"
                                        min="0"
                                        v-check-min-max-value
                                        max="100"
                                        step=".01"
                                    ></b-form-input>
                                </template>
                                <template #cell(status)="row">
                                    <b-badge v-if="row.item.status!=''" variant="success" class="text-uppercase">
                                        {{ toUpperCase(row.item.status) }}
                                    </b-badge>
                                </template>
                            </b-table>

                            <div class="d-flex align-items-right mt-4">
                                <b-button
                                    variant="primary"
                                    class="mr-2"
                                    @click="showModal('saveModal')">
                                    {{ $t('save') }}
                                </b-button>
                                <b-button
                                    variant="success"
                                    class="mr-2"
                                    @click="showModal('publishModal')"
                                >{{ $t('publish') }}
                                </b-button>
                            </div>
                        </div>
                        <div v-if="class_id && !isStudents">
                            <div class="spinner-border text-light mx-auto d-flex"></div>
                        </div>
                    </div>
                </div>

                <b-modal
                    ref="saveModal"
                    id="saveModal"
                    :size="size"
                    centered
                    :title="$t('warning')"
                >
                    <template>
                        <p class="my-4">
                            {{ missingScores > 0 ? $t('prep_exam_missing_scores_confirmation_modal') : $t('prep_exam_confirmation_modal') }}
                        </p>
                    </template>
                    <template #modal-footer>
                        <div class="w-100">
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right"
                                @click="save"
                            >
                                {{ $t('confirmation') }}
                            </b-button>
                        </div>
                    </template>
                </b-modal>

                <b-modal
                    ref="publishModal"
                    id="publishModal"
                    :size="size"
                    centered
                    :title="$t('warning')"
                >
                    <template>
                        <p class="my-4">
                            {{ $t('prep_exam_publish_modal') }}
                        </p>
                    </template>
                    <template #modal-footer>
                        <div class="w-100">
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right"
                                @click="publish"
                            >
                                {{ $t('confirmation') }}
                            </b-button>
                        </div>
                    </template>
                </b-modal>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

import PrepExamNotes from '@/services/PrepExamNotes';
import ActiveModuleSelectbox from '@/components/interactive-fields/ActiveModuleSelectbox';
import NoteAssessment from '@/components/interactive-fields/NoteAssessment';

import moment from 'moment';
import qs from "qs";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        ActiveModuleSelectbox,
        NoteAssessment
    },
    metaInfo() {
        return {
            title: this.$t('prep_grade_entry')
        };
    },
    data() {
        return {
            isModule: false,
            class_id: '',
            exam_id: '',
            isClass: false,
            setClass: [],
            activeClass: '',
            isDateInput: false,
            theDate: moment(new Date()).format('YYYY-MM-DD'),
            selectDate: null,
            postDate: null,
            isStudents: false,
            setStudents: [],
            scheduleIds: [],
            isTable: false,
            listStudent: [],
            size: 'md',
            selected: '',
            date: null,
            CommonModalMode: false,
            moduleSelected: null,
            arrayDates: [],
            visible: false,
            updated: false,
            fields: [],

            isNoteAssessment: false,
            itemNoteAssessment: [],
            isNewExam: false,
            getExamName: '',
            schedule_name: '',

            fieldStudent: [
                {key: 'student_number', label: this.toUpperCase('student_number'), sortable: true},
                {key: 'name_surname', label: this.toUpperCase('name_surname'), sortable: true},
                {key: 'score', label: this.toUpperCase('exam_score'), sortable: true},
                {key: 'status', label: this.toUpperCase('status'), sortable: true}
            ],

            attributes: [],
            scores: [],
            missingScores: 0
        };
    },
    watch: {
        moduleSelected: function(newValue) {
            this.isModule = true;
            this.setModule(newValue);
        }
    },

    computed: {},

    methods: {
        showModal(modalName) {
            if (modalName == 'saveModal') {
                this.scores = [];
                this.setStudents.forEach((item) => {
                    let student = {
                        score: parseFloat(item.score),
                        student_program_id: item.student_program_id
                    };
                    this.scores.push(student);
                });
                this.missingScores = this.scores.filter(item => {
                    return item.score == null
                }).length;
            }
            this.$refs[modalName].show();
        },
        hideModal(modalName) {
            this.$refs[modalName].hide();
        },

        handleInput(student_program_id, input) {
            this.setStudents[student_program_id].score = input.target.value;
        },

        refresh() {
            this.date = null;
            this.isDateInput = false;
            this.fields = [];
            this.scheduleIds = [];
            this.selectDate = '';
            this.isStudents = false;
            this.setStudents = [];
            this.isTable = false;
            this.listStudent = [];
            this.arrayDates = [];
            this.activeClass = '';
            this.isNoteAssessment = false;
            this.itemNoteAssessment = [];
            this.isNewExam = false;
            this.getExamName = '';
            this.schedule_name = '';
        },

        getExams(itemData, key, class_id) {
            this.refresh();
            this.class_id = class_id;
            this.activeClass = key;
            this.schedule_name =
                itemData.academic_year +
                ' ' +
                this.$t('module') +
                itemData.module +
                ' ' +
                itemData.level +
                ' - ' +
                itemData.class;

            PrepExamNotes.getPrepExams(class_id).then((response) => {
                if (response.data.success === true) {
                    this.isNoteAssessment = true;
                    response.data.data.map((item) => {
                        let exams = [];
                        let examCount = 1;
                        item.exams.map((itemExam) => {
                            exams.push({
                                id: itemExam.id,
                                assessment: item.exam_name + ' ' + examCount,
                                date: itemExam.date,
                                numberOfStudent: 0,
                                isFinalized: itemExam.is_finalized,
                                exam_definition_id: item.id,
                                rank: examCount
                            });
                            examCount++;
                        });

                        let isNewExam = true;
                        for (; examCount <= item.number_of_exams; examCount++) {
                            exams.push({
                                id: 0,
                                assessment: item.exam_name + ' ' + examCount,
                                date: null,
                                numberOfStudent: 0,
                                isFinalized: false,
                                isNewExam: isNewExam,
                                exam_definition_id: item.id,
                                rank: examCount
                            });
                            isNewExam = false;
                        }

                        this.itemNoteAssessment.push({
                            id: item.id,
                            examName: item.exam_name,
                            numberOfExams: item.number_of_exams,
                            effectRatio: item.effect_ratio,
                            exams: exams,
                            numberOfDoneExams: item.exams.length
                        });
                    });
                }
            });
        },

        setModule(selected) {
            if (!selected) {
                return;
            }
            this.refresh();
            this.isClass = false;
            PrepExamNotes.getSchedulesInstructor(selected).then((response) => {
                this.isClass = true;
                this.setClass = response.data.data;
            });
        },

        getStudents(class_id, exam_id) {
            if (!class_id || !exam_id) {
                return;
            }
            this.isStudents = false;
            this.isNoteAssessment = false;
            this.exam_id = exam_id;

            const config = {
                params: {
                    filter: {
                        class_id: class_id
                    },
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            PrepExamNotes.getStudents(config).then((response) => {
                if (response.data.success === true) {
                    var listStudent = [];
                    response.data.data.map(function(value, key) {
                        listStudent[value.student_program_id] = {
                            id: value.id,
                            order: key + 1,
                            student_number: value.student_number,
                            name_surname: value.name + ' ' + value.surname,
                            student_program_id: value.student_program_id,
                            score: null
                        };
                    });

                    PrepExamNotes.getPrepExamStudents(exam_id).then((re) => {
                        if (re.data.success === true) {
                            listStudent.map((itemStudent, indexStudent) => {
                                itemStudent.status = '';
                                if (re.data.data[itemStudent.student_program_id] != undefined) {
                                    itemStudent.score = re.data.data[itemStudent.student_program_id][0].score;
                                    itemStudent.status = re.data.data[itemStudent.student_program_id][0].is_finalized ? 'published' : 'saved';
                                }
                            });
                        }
                    });

                    this.isStudents = true;
                    this.setStudents = listStudent;
                }
            });
        },

        save() {
            if(this.checkPermission('prepexamscore_store')){
                let data = {
                    prep_exam_id: this.exam_id,
                    scores: this.scores
                };

                PrepExamNotes.postPrepExamScore(data)
                    .then((res) => {
                        this.hideModal('saveModal');
                        this.$toast.success(this.$t('api.' + res.data.message));
                        this.getStudents(this.class_id, this.exam_id);
                    })
                    .catch((e) => {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    });
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        publish() {
            if(this.checkPermission('prepexam_finalize')){
                PrepExamNotes.putPrepExamFinalized(this.exam_id)
                    .then((res) => {
                        this.hideModal('publishModal');
                        this.$toast.success(this.$t('api.' + res.data.message));
                    })
                    .catch((e) => {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    });
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        activeModule(module){
            this.moduleSelected = module;
        }
    }
}
</script>

